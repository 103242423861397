import axios from "axios";
import baseUrl, { postRequest } from "./httpRequests";

async function userLogin(values) {
  var res;

  await axios
    .post(
      baseUrl + "users/login/",
      {},
      {
        auth: {
          username: values.email,
          password: values.password,
        },
      }
    )
    .then(function (response) {
      if (response) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("isAuthenticated", true);
        res = response;
      } else {
        throw new Error(response);
      }
    })
    .catch(function (error) {
      res = error.response;
      return res;
    });
  if (res !== undefined) {
    return res;
  } else
    return {
      message: "The server is taking too long to respond. Please try again.",
    };
}

async function getUserSession(userId) {
  try {
    const response = await axios.get(
      baseUrl + "users/login-session/" + userId + "/"
    );
    return response.data;
  } catch (error) {
    return error.response;
  }
}

async function updateUserSessionStatus(userId) {
  try {
    const response = await axios.post(
      baseUrl + "users/set-login-status/" + userId + "/"
    );
    return response.data;
  } catch (error) {
    return error.response;
  }
}

async function updateUserSession(userId, values) {
  const formData = new FormData();
  formData.append("device_info_2", values.device_info_2);
  try {
    const response = await axios.put(
      baseUrl + "users/edit/login-session/" + userId + "/",
      formData
    );
    return response.data;
  } catch (error) {
    return error.response;
  }
}

async function createUserSession(values) {
  const formData = new FormData();
  formData.append("user_id", values.user_id);
  formData.append("device_info", values.device_info);

  const res = await postRequest(`/users/login-session/`, formData);
  if (res.status === 200) {
    return res;
  }
  return undefined;
}

const authFunctions = {
  userLogin,
  getUserSession,
  createUserSession,
  updateUserSessionStatus,
  updateUserSession,
};

export default authFunctions;
