// import Dashboard  from './components/dashboard/Dashboard';
import routes from './routes';
import React from 'react';

function App() {
	return (
		// <React.StrictMode>
		<div className="container-fluid">
			<>
				{routes}
			</>
		</div>
		// </React.StrictMode>
	);
}

export default App;

// Router.js
// Theme in Material UI
