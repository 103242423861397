import React, { useState, useRef, useEffect } from 'react';
import {
	RadioGroup,
	Radio,
	FormControlLabel,
	Button,
	TableRow,
} from '@material-ui/core';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import './examStyles.css';
// import HeadPhoneImg from '../../assets/images/headphone.png'
// import omLogo from '../../assets/images/om.png'

import { useHistory } from 'react-router-dom';

// HTTP routes
import { postRequest } from '../../requests/httpRequests';

// User Context
import { useToken, useScore } from '../../context/UserContext';
import useUnsavedChangesWarning from '../../hooks/useUnsavedChangesWarning';

import RotateScreen from '../../assets/images/rotate-screen.gif';

// React Timer HOOK
import MyTimer from './Timer.js';

const Exam = ({ questions, setNumber }) => {

	// detect unsaved change 
	const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

	const [userSubmittedAnswers, setSubmittedAnswers] = useState({});

	// Get Token & score from Context
	let token = useToken();
	const { setScore } = useScore();

	// Use History Hook
	let history = useHistory();

	// This object is submitted to the backend
	let submittedAnswers = {};

	// to view question list
	const questionsRef = useRef();
	const pageRef = useRef();
	const overlayRef = useRef();
	const audioRef = useRef();

	// const [second, setSecond] = useState('00');
	// const [minute, setMinute] = useState('50');
	const [isActive, setTimerIsActive] = useState(false);
	// const [counter, setCounter] = useState();

	const [remainingQuestionsCounter, setRemainingQuestionsCounter] = useState(0);

	const time = new Date();
	time.setSeconds(time.getSeconds() + 3000);


	useEffect(() => {
		let intervalId;
		setDirty();
		// if (isActive) {
			// MyTimer()
			// intervalId = setInterval(() => {
			// 	const secondCounter = counter % 60;
			// 	const minuteCounter = Math.floor(counter / 60);

			// 	const computedSecond = String(secondCounter).length === 1 ? `0${secondCounter}` : secondCounter;
			// 	const computedMinute = String(minuteCounter).length === 1 ? `0${minuteCounter}` : minuteCounter;

			// 	setSecond(computedSecond);
			// 	setMinute(computedMinute);

			// 	setCounter(counter => counter - 1);
			// }, 1000);
		// }
		// if (counter === 599) {
		// 	alert("Only Ten Minutes Remaining. Hurry Up.");
		// }

		// if (counter === 300) {
		// 	const playAudio = async () => {
		// 		const importRes = await import('../../assets/ping.wav'); // make sure the path is correct
		// 		var audio = new Audio(importRes.default);
		// 		try {
		// 		  await audio.play();
		// 		  alert("Only Five Minutes Remaining. Start reviewing your answers.");
		// 		//   console.log("Playing audio");
		// 		} catch (err) {
		// 		  console.log("Failed to play, error: " + err);
		// 		}
		// 	}
		// 	playAudio();
		// }

		// if (counter === 0) {
			
		// 	setTimerIsActive(false);
		// }

		return () => clearInterval(intervalId);
	}, [isActive,  setDirty])


	// setting current question as first, this uses array indexing so 0 = question 1
	const [currentQ, setCurrentQ] = useState(0);

	// const [viewQuestions, setViewQuestions] = useState(true);

	const viewAllQuestions = (value) => {
		if (value) {
			overlayRef.current.style.display = "block";
		} else {
			overlayRef.current.style.display = "none";
		}
	}

	const handleQnoClick = (e) => {
		setTimerIsActive(true);
		if (currentAudio) {
			currentAudio.target.pause();
		}
		viewAllQuestions(false);
		let newQ = parseInt(e.target.innerHTML) - 1;
		if (newQ !== currentQ) {
			questionsRef.current.childNodes[currentQ].classList.remove('question-flex');
			setCurrentQ(newQ);
		}
	};

	// find total no of questions 
	var totalQuestions = 0;
	if (questions) {
		if (questions.groups) {
			for (var i = 0; i < questions.groups.length; i++) {
				for (var j = 0; j < questions.groups[i].questions.length; j++) {
					totalQuestions++;
				}
			}
		}
	}

	const [currentAudio, setCurrentAudio] = useState();

	// triggres when audio starts playing, starts the timer again 
	const audioIsPlaying = (e) => {
		setTimerIsActive(true);
		setCurrentAudio(e);
	}

	const handleAudioPlay = (e) => {
		// pause timer on click play 
		// setTimerIsActive(false);
		if (e) {
			if (e.target) {
				if (e.target.lastElementChild) {
					if (e.target.lastElementChild.firstElementChild) {
						if (currentAudio) {
							currentAudio.target.pause();
						}
						e.target.disabled = true;
						e.target.style.border = '3px solid blue';
						e.target.lastElementChild.firstElementChild.play();
					}
				}
			}
		}
	}

	const handleOptionAudioPlay = (e) => {
		// pause timer on click play 
		// setTimerIsActive(false);
		if (e) {
			if (e.target) {
				if (e.target.lastElementChild) {
					if (e.target.lastElementChild.firstElementChild) {
						if (currentAudio) {
							if (currentAudio.target.paused){
								e.target.disabled = true;
								e.target.style.border = '3px solid blue';
								e.target.lastElementChild.firstElementChild.play();
							}
						}
						else {
							e.target.disabled = true;
							e.target.style.border = '3px solid blue';
							e.target.lastElementChild.firstElementChild.play();
						}
					}
				}
			}

			// // Audio Auto Play By Samrat P
			// let audioNos = e.target.parentNode.parentNode.children.length;
			// if (currentAudio) {
			// 	currentAudio.target.pause();
			// }
			// e.target.parentNode.parentNode.children[0].lastElementChild.style.border = '3px solid blue';
			// e.target.parentNode.parentNode.children[0].lastElementChild.firstElementChild.firstElementChild.play();
			// for (let i = 0; i < audioNos; i++) {
			// 	e.target.parentNode.parentNode.children[i].lastElementChild.disabled = true;
			// }
		}
	}

	// // // Audio Auto Play By Samrat P
	// const changeOptionAudio = (e, num) => {
	// 	// pause timer on click play 
	// 	setTimerIsActive(false);
	// 	let audioNos = e.target.parentNode.parentNode.parentNode.parentNode.children.length;
	// 	num = num + 1;
	// 	if (num < audioNos) {
	// 		e.target.parentNode.parentNode.parentNode.parentNode.children[num].lastElementChild.style.border = '3px solid blue';
	// 		e.target.parentNode.parentNode.parentNode.parentNode.children[num].lastElementChild.firstElementChild.firstElementChild.play()
	// 	} else {
	// 		currentAudio.target.pause();
	// 		setTimerIsActive(true);
	// 	}
	// }

	const handleNextPrev = (btn) => {
		if (currentAudio) {
			currentAudio.target.pause();
		}
		if (btn === 'prev' && currentQ > 0) {
			questionsRef.current.childNodes[currentQ].classList.remove('question-flex');
			let nextQ = currentQ - 1;
			setCurrentQ(nextQ);
		} else if (btn === 'next' && currentQ < totalQuestions - 1) {
			questionsRef.current.childNodes[currentQ].classList.remove('question-flex');
			let nextQ = currentQ + 1;
			setCurrentQ(nextQ);
		}
		pageRef.current.scrollTo(0, 0);
	};

	useEffect(() => {
		if (questionsRef.current.childNodes[currentQ]) {
			questionsRef.current.childNodes[currentQ].classList.add('question-flex');
		}
	}, [currentQ]);

	var readingQNo = 0;
	var listeningQno = 0;

	// Record all the questions and answers
	const handleChange = (event, questionCounter) => {
		// Get root consisting of 4 options p tags
		let parentDiv = event.target.parentNode.parentNode.parentNode.parentNode.parentNode.childNodes;

		if (parentDiv) {
			// check if each p tag has question-checked class
			parentDiv.forEach(element => {
				if (element.children[0].children[1].classList.contains('question-checked')) {
					element.children[0].children[1].classList.remove('question-checked')
				}
			});  // end of foreach

			// Get current selected option
			event.target.parentNode.parentNode.parentNode.childNodes[1].classList.add('question-checked')
		}

		let selectViewAllQues = questionCounter - 2;
		questionCounter -= 1;
		if ((20 - questionCounter) >= 0) {
			overlayRef.current.childNodes[0].childNodes[0].childNodes[selectViewAllQues].classList.add('question-answered')
		}
		else {
			selectViewAllQues -= 20
			overlayRef.current.childNodes[0].childNodes[1].childNodes[selectViewAllQues].classList.add('question-answered')
		}

		setRemainingQuestionsCounter(remainingQuestionsCounter + 1);

		const { name, value } = event.target;
		setSubmittedAnswers((prevValues) => {
			return { ...prevValues, [name]: value };
		});

	};
	const handleSubmit = async (event) => {
		setPristine();
		let answer_formatter = [];
		// Appends all the answers to the answer_formatter array
		for (const [key, value] of Object.entries(userSubmittedAnswers)) {
			answer_formatter.push({
				question_no: String(key),
				given_answer: String(value),
			});
		}
		submittedAnswers.answers = answer_formatter;
		let res = await postRequest(
			`/exam/submit/${setNumber}/`,
			submittedAnswers,
			token
		);
		if (res.status === 200) {
			setScore(res.data);
			history.push(`/results`);
		}
	};

	// Display question images and audio
	const display_question_media = (question_audio_url, question_image_url) => {
		if (question_image_url.length !== 0 && question_audio_url.length !== 0) {
			return (
				<>
					<div class="question-image">
						<img src={question_image_url} alt={'Question Not Available.'} />
					</div>
					<div>
						<button onClick={(e) => handleAudioPlay(e)} className="audio-button">
							<AudioPlayer
								style={{ visibility: 'hidden' }}
								showSkipControls="false"
								preload="auto"
								src={question_audio_url}
								onPlay={e => audioIsPlaying(e)}
								ref={audioRef}
							/>
						</button>

					</div>
				</>
			);
		}
		if (question_image_url.length !== 0) {
			return (
				<div class="question-image">
					<img src={question_image_url} alt={'Question Not Available.'} />
				</div>
			);
		}
		if (question_audio_url.length !== 0) {
			return (
				<div>
					<button onClick={(e) => handleAudioPlay(e)} className="audio-button">
						<AudioPlayer
							style={{ visibility: 'hidden' }}
							showSkipControls="false"
							preload="auto"
							src={question_audio_url}
							onPlay={e => audioIsPlaying(e)}
							ref={audioRef}
						/></button>

				</div>
			);
		}
	};

	const display_option_media = (question, questionCounter) => {
		// console.log(question);
		if (question) {

			return (
				<RadioGroup
					aria-label="options"
					name={question.question_id}
					value={userSubmittedAnswers.option_number}
					onChange={(e) => handleChange(e, questionCounter)}
				>
					<p style={{ display: 'flex', flexDirection: 'row' }}>
						{/* {(optionCounter = 1 + '. ')} */}
						<FormControlLabel
							value="1"
							label={optionCounter = 1}
							control={<Radio />}
							style={{ flexDirection: 'column' }}
						/>

						{question.question_option_content_1.split('.').pop() === 'png' ||
							question.question_option_content_1.split('.').pop() === 'PNG' ||
							question.question_option_content_1.split('.').pop() === 'JPG' ||
							question.question_option_content_1.split('.').pop() === 'JPEG' ||
							question.question_option_content_1.split('.').pop() === 'jpg' ||
							question.question_option_content_1.split('.').pop() === 'jpeg' ? (
							<img src={question.question_option_content_1} alt="Preview Unavailable." />
						) : question.question_option_content_1.split('.').pop() === 'mp3' ||
							question.question_option_content_1.split('.').pop() === 'MP3' ? (
							<button onClick={(e) => handleOptionAudioPlay(e)} className="audio-button">
								<AudioPlayer
									style={{ visibility: 'hidden' }}
									showSkipControls="false"
									preload="auto"
									src={question.question_option_content_1}
									onPlay={e => audioIsPlaying(e)}
									// onEnded={e => changeOptionAudio(e, 0)} // // Audio Auto Play By Samrat P
									ref={audioRef}
								/>
							</button>
						) :
							(
								question.question_option_content_1
							)}
					</p>
					<p style={{ display: 'flex', flexDirection: 'row' }}>
						{/* {++optionCounter + '. '} */}
						<FormControlLabel
							value="2"
							label={++optionCounter}
							control={<Radio className="hello" />}
							style={{ flexDirection: 'column' }}
						/>
						{question.question_option_content_2.split('.').pop() === 'png' ||
							question.question_option_content_2.split('.').pop() === 'PNG' ||
							question.question_option_content_2.split('.').pop() === 'JPG' ||
							question.question_option_content_2.split('.').pop() === 'JPEG' ||
							question.question_option_content_2.split('.').pop() === 'jpg' ||
							question.question_option_content_2.split('.').pop() === 'jpeg' ? (
							<img src={question.question_option_content_2} alt="Preview Unavailable." />
						) : question.question_option_content_2.split('.').pop() === 'mp3' ||
							question.question_option_content_2.split('.').pop() === 'MP3' ? (
							<button onClick={(e) => handleOptionAudioPlay(e)} className="audio-button">
								<AudioPlayer
									style={{ visibility: 'hidden' }}
									showSkipControls="false"
									preload="auto"
									src={question.question_option_content_2}
									onPlay={e => audioIsPlaying(e)}
									// onEnded={e => changeOptionAudio(e, 1)} // // Audio Auto Play By Samrat P
									ref={audioRef}
								/>
							</button>
						) :
							(
								question.question_option_content_2
							)}
					</p>
					<p style={{ display: 'flex', flexDirection: 'row' }}>
						{/* {++optionCounter + '. '} */}
						<FormControlLabel
							value="3"
							label={++optionCounter}
							control={<Radio />}
							style={{ flexDirection: 'column' }}
						/>
						{question.question_option_content_3.split('.').pop() === 'png' ||
							question.question_option_content_3.split('.').pop() === 'PNG' ||
							question.question_option_content_3.split('.').pop() === 'JPG' ||
							question.question_option_content_3.split('.').pop() === 'JPEG' ||
							question.question_option_content_3.split('.').pop() === 'jpg' ||
							question.question_option_content_3.split('.').pop() === 'jpeg' ? (
							<img src={question.question_option_content_3} alt="Preview Unavailable." />
						) : question.question_option_content_3.split('.').pop() === 'mp3' ||
							question.question_option_content_3.split('.').pop() === 'MP3' ? (
							<button onClick={(e) => handleOptionAudioPlay(e)} className="audio-button">
								<AudioPlayer
									style={{ visibility: 'hidden' }}
									showSkipControls="false"
									preload="auto"
									src={question.question_option_content_3}
									onPlay={e => audioIsPlaying(e)}
									// onEnded={e => changeOptionAudio(e, 2)} // // Audio Auto Play By Samrat P
									ref={audioRef}
								/>
							</button>
						) :
							(
								question.question_option_content_3
							)}
					</p>
					<p style={{ display: 'flex', flexDirection: 'row' }}>
						{/* {++optionCounter + '. '} */}
						<FormControlLabel
							label={++optionCounter}
							value="4"
							control={<Radio />}
							style={{ flexDirection: 'column' }}
						/>
						{question.question_option_content_4.split('.').pop() === 'png' ||
							question.question_option_content_4.split('.').pop() === 'PNG' ||
							question.question_option_content_4.split('.').pop() === 'JPG' ||
							question.question_option_content_4.split('.').pop() === 'JPEG' ||
							question.question_option_content_4.split('.').pop() === 'jpg' ||
							question.question_option_content_4.split('.').pop() === 'jpeg' ? (
							<img src={question.question_option_content_4} alt="Preview Unavailable." />
						) : question.question_option_content_4.split('.').pop() === 'mp3' ||
							question.question_option_content_4.split('.').pop() === 'MP3' ? (
							<button onClick={(e) => handleOptionAudioPlay(e)} className="audio-button">
								<AudioPlayer
									style={{ visibility: 'hidden' }}
									showSkipControls="false"
									preload="auto"
									src={question.question_option_content_4}
									onPlay={e => audioIsPlaying(e)}
									// onEnded={e => changeOptionAudio(e, 3)} // // Audio Auto Play By Samrat P
									ref={audioRef}
								/>
							</button>
						) :
							(
								question.question_option_content_4
							)}
					</p>
				</RadioGroup>
			);
		}
	};

	var pageContent = '';
	var pageContent1 = '';
	var pageContent2 = '';
	var readingQuestionList = '';
	var listeningQuestionList = '';
	var questionCounter = 1;
	var optionCounter = 1;
	var questionListCounter = 1;

	if (questions) {
		if (questions.groups) {
			for (let i = 0; i < questions.groups.length; i++) {
				for (let j = 0; j < questions.groups[i].questions.length; j++) {
					if (questions.groups[i].question_group_type === 'Reading') {
						readingQNo++;
					} else if (questions.groups[i].question_group_type === 'Listening') {
						if (questions.groups[j]) {
							listeningQno++;
						}
					}
				}
			}
		}

		readingQuestionList = questions.groups
			.filter((questions) => questions.question_group_type === 'Reading')
			.map((questionn, indexI) => questionn.questions.map((ques, indexJ) => (
				<>
					<span
						className={`questionBoxes ${"questionBox-" + questionListCounter}`}
						onClick={(e) => handleQnoClick(e)}
					>
						{questionListCounter++}
					</span>
				</>
			))
			);
		// console.log(questions);
		listeningQuestionList = questions.groups
			.filter((question) => question.question_group_type === 'Listening')
			.map((questionn, indexI) => questionn.questions.map((ques, indexJ) => (
				<>

					<span
						className={`questionBoxes ${"questionBox-" + questionListCounter}`}
						onClick={(e) => handleQnoClick(e)}
					>
						{questionListCounter++}
					</span>
				</>
			))
			);

		pageContent1 = (
			<>
				<div className="overlay-questions" ref={overlayRef}>
					<div className="question-main-box">
						<div className="reading-main-box">
							{readingQuestionList}
						</div>
						<div className="listening-main-box">
							{listeningQuestionList}
						</div>
					</div>
				</div>
				<div className="exam-header">
					<div className="set-details">
						<span>{questions.set_name}</span>
						<span>Student</span>
					</div>
					<div className="question-details">
						<div onClick={() => viewAllQuestions(true)}>확인문제 (View All Questions)</div>
						<div>총 (Total) : {readingQNo + listeningQno}</div>
						<div>나머지 (Remaining) : {40 - parseInt(remainingQuestionsCounter)}</div>
						<div>시험 시간 : <MyTimer expiryTimestamp={time} handleSubmit={handleSubmit} isActive={isActive} />
						</div>
					</div>
				</div>
			</>
		);

		var typeofQuestion;
		pageContent2 = questions.groups.map((question, indexI) =>
			question.questions.sort((a, b) => { return a.question_id - b.question_id }).map((qno, indexJ) => (
				<div
					key={indexJ}
					className={`question ${'question' + questionCounter}`}
				>
					{/* <img src={omLogo} alt="om Logo" className="omLogoBg"/> */}
					<div>
						<h4 style={{ display: 'inline' }}>&nbsp;{question.question_group_type === 'Reading' ? typeofQuestion = '읽기 (Reading)' : typeofQuestion = '듣기 (Listening)'}</h4>
						<h4 style={{ display: 'inline' }}>{question.question_group_name}</h4>
						{question.question_group_image && (
							<img
								style={{ width: '30%' }}
								src={question.question_group_image}
								alt="Preview Unavailable."
							/>
						)}
					</div>
					<div className="qno-all">
						<div className="question-left">

							<p>
								{questionCounter++ + ' ) '}
								{qno.question_content}
							</p>

							{qno.question_instruction && (<p style={{ border: '1px solid black', padding: '10px', margin: '10px', textAlign: 'center' }}>{qno.question_instruction}</p>)}

							{display_question_media(
								qno.question_audio_file,
								qno.question_image_file
							)}
						</div>
						<div className="question-right">
							{display_option_media(qno, questionCounter)}
							<div className="nextprevBtn">
								<Button
									variant="contained"
									color="default"
									onClick={() => handleNextPrev('prev')}
								>
									- 이전 (Prev)
								</Button>
								<span className='vertical-line'></span>
								<Button
									variant="contained"
									color="default"
									onClick={() => handleNextPrev('next')}
								>
									다음 (Next) -
								</Button>
							</div>
							<Button
								variant="contained"
								color="primary"
								onClick={handleSubmit}
								// style={{ display: viewQuestions ? 'none' : 'block' }}
								className="submitButton"
							>
								제출 (Submit)
							</Button>
						</div>
					</div>
				</div>
			))
		);

		var pageContent22 = <div class="question-main-body" ref={questionsRef}>{pageContent2}</div>;

		pageContent = [pageContent1, pageContent22];

		// show first question on load
	}

	return (
		<>
			<div id="landscapeMode" style={{ display: 'none' }}>
				<img src={RotateScreen} alt="rotateScreen" />
				<p>Please rotate your device.</p>
			</div>
			<TableRow ref={pageRef} style={{ width: '100%' }}>
				{/* {viewQuestions ? totalQuestionList : pageContent} */}
				{pageContent}
				{Prompt}
			</TableRow>
		</>
	);
};

export default Exam;