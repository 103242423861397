import clsx from "clsx";
import React, { useState, useEffect } from "react";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
// Left Side Naviagtion Options
import { mainListItems, secondaryListItems } from "./listItems";
import { useLocation } from "react-router-dom";

// Get User Token Info
import { useToken } from "../../context/UserContext";
import jwt_decode from "jwt-decode";

// Request to Backend
import { getRequest, getSpecificRequest } from "../../requests/httpRequests";

// React Toast for Notifications
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import omLogo from "../../assets/images/om-footer.png";

function Copyright() {
  return (
    <>
      <div style={{ margin: "auto", width: "80%", height: "150px" }}>
        <img
          style={{ width: "100%", height: "100%" }}
          src={omLogo}
          alt="Institue Footer"
        />
      </div>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://google.com/">
          Silverback Technology
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function DashboardLayout(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  // declare a state to store user details
  const [user, setUser] = useState({
    avatar: "",
    role: "",
    email: "",
    full_name: "",
  });
  const location = useLocation();
  //context custom hook
  const token = useToken();
  useEffect(() => {
    if (token) {
      var tokenDetails = jwt_decode(token);
      const get_avatar = async () => {
        let response = await getSpecificRequest(
          "/users",
          tokenDetails.id,
          token
        );
        if (response.status === 200) {
          setUser({
            avatar: response.data.profile_picture,
            full_name: response.data.full_name,
          });
        } else {
          toast.error("Your profile cannot be loaded right now.");
        }
      };
      // Get Token details and save it to user state
      setUser({
        role: tokenDetails.role,
        email: tokenDetails.email,
      });

      // Get Profile Picture and save it to user state
      get_avatar();
    }
  }, [location.pathname, token]);

  const getInitials = () => {
    let name = String(user.full_name);
    let initials = name.split(" ");

    if (initials.length > 1) {
      initials = initials.shift().charAt(0) + initials.pop().charAt(0);
    } else {
      initials = name.substring(0, 2);
    }

    return initials.toUpperCase();
  };

  const handleLogout = async () => {
    const token = localStorage.getItem("token");
    const res = await getRequest("/users/logout/", token);
    if (res.status === 200) {
      localStorage.removeItem("token");
      localStorage.removeItem("isAuthenticated");
      props.history.replace("/");
    }
  };

  // console.log(props.component.props.location.pathname);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, open && classes.appBarShift)}
          style={{
            display:
              props.component.props.location.pathname === "/take-exam"
                ? "none"
                : "block",
          }}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <Avatar alt={user.full_name} src={user.avatar}>
              {" "}
              {getInitials()}
            </Avatar>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              &nbsp;&nbsp;&nbsp;&nbsp; Dashboard
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={16} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              onClick={handleLogout}
            >
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>{mainListItems}</List>
          <Divider />
          <List>{secondaryListItems}</List>
        </Drawer>
        <main className={classes.content}>
          <div
            // className={classes.appBarSpacer}
            style={{
              minHeight:
                props.component.props.location.pathname === "/take-exam"
                  ? "0px !important"
                  : "64px !important",
            }}
          />
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              {/* Load Components */}
              {props.component}
            </Grid>
            <Box pt={5}>
              <Copyright />
            </Box>
          </Container>
        </main>
      </div>
    </>
  );
}
