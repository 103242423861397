import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  link:{
    color: '#FFF',
    textDecoration: 'None',

    '&:hover': {
        color: '#000',
        fontSize: '110%',
        fontWeight: 'bolder',
        transition: '.25s'
    }
  }
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <Link to="/" className={classes.link}> Home </Link>
          </Typography>
          <Link to='/login' className={classes.link} style={{marginRight: '20px'}}>Login</Link>
        </Toolbar>
      </AppBar>
    </div>
  );
}
