import axios from "axios";

// const config = {
// 	headers: { 'x-access-token': token, 'content-type': 'application/json' },
// };
const baseUrl = "https://cbt-korean-backend.ktm.yetiappcloud.com/";
// const baseUrl = "http://192.168.1.93:5000/";
// const baseUrl = "http://127.0.0.1:5000/";

export default baseUrl;

// NOTE: some changes will be made to this page, CORS erroe showing up in error 401,
// requests for get, pass in the url "/url/"

export const getRequest = async (url, token = "") => {
  try {
    const response = await axios({
      method: "get",
      url: baseUrl + url,
      headers: {
        "x-access-token": token,
      },
    });
    const responseData = response;
    return responseData;
  } catch (err) {
    return err;
  }
};

// requests for get specific, pass in the url "/url/"
export const getSpecificRequest = async (url, id, token = "") => {
  try {
    // return url, id
    const response = await axios({
      method: "get",
      url: baseUrl + url + "/" + id + "/",
      headers: {
        "x-access-token": token,
      },
    });
    const responseData = response;
    return responseData;
  } catch (err) {
    return err;
  }
};

// requests for get, pass in the url and the formdata. Format; "/url/"
export async function postRequest(url, formData, token = "") {
  try {
    const response = await axios({
      method: "post",
      url: baseUrl + url,
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json;",
      },
      data: formData,
    });
    const responseData = response;
    return responseData;
  } catch (e) {
    return e.response;
  }
}

// requests for get, pass in the url, formdata and id. Format; "/url/"
export const editRequest = async (url, formData, id, token = "") => {
  try {
    const response = await axios({
      method: "put",
      url: baseUrl + url + "/" + id + "/",
      headers: {
        "x-access-token": token,
        "Content-Type": "multipart/form-data;",
      },
      data: formData,
    });
    const responseData = response;
    return responseData;
  } catch (e) {
    return e.response;
  }
};

// requests for get, pass in the url, formdata and id. Format; "/url/"
export const deleteRequest = async (url, id, token = "") => {
  try {
    const response = await axios({
      method: "delete",
      url: baseUrl + url + "/" + id + "/",
      headers: {
        "x-access-token": token,
        "Content-Type": "multipart/form-data;",
      },
    });
    const responseData = response;
    return responseData;
  } catch (e) {
    return e.response;
  }
};
