import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import getInitials from '../../utils/getInitials';

// react toast
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
	getSpecificRequest,
} from '../../requests/httpRequests';


const isBrowser = typeof(window) !== "undefined";

const ResultsList = ({ results, setAnswersheet, setViewSingle,  ...rest }) => {
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const scrollToTop = () =>{
    if (isBrowser) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = results.map((customer) => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }
    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleViewResults = (event, user) => {
    event.preventDefault();
    const getUsers = async () => {
			let response = await getSpecificRequest(`exam/answer-sheet`, user.exam_details.exam_record_id);
			if (response.status === 200) {
        setAnswersheet(user)
        setViewSingle(1)
			}
      else{
        toast.error('Something is wrong. Please try reloadig the page.')
      }
		};
		getUsers();
  }
  
  return (
    <>
      {/* the toast container, declared first  */}
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
			/>
      <Card {...rest}>
        {/* <PerfectScrollbar> */}
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.length === results.length}
                      color="primary"
                      indeterminate={
                        selectedCustomerIds.length > 0
                        && selectedCustomerIds.length < results.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>
                    Student Name
                  </TableCell>
                  <TableCell>
                    Obtained Marks
                  </TableCell>
                  <TableCell>
                    Student Email
                  </TableCell>
                  <TableCell>
                    Phone Number
                  </TableCell>
                  <TableCell>
                    Exam Date
                  </TableCell>
                  <TableCell style={{textAlign: "center"}}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.slice(0, limit).map((customer) => (

                  <TableRow
                    hover
                    key={customer.id}
                    selected={selectedCustomerIds.indexOf(customer.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedCustomerIds.indexOf(customer.id) !== -1}
                        onChange={(event) => handleSelectOne(event, customer.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Avatar
                          src={customer.student_pic}
                          sx={{ mr: 2 }}
                        >
                          {getInitials(customer.student_name)}
                        </Avatar>
                        <Typography
                          color="textPrimary"
                          variant="body1"
                        >
                          {customer.student_name}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell style={{textAlign: "center"}}>
                      {customer.exam_details.obtained_score}
                    </TableCell>
                    <TableCell>
                      {customer.student_email}
                    </TableCell>
                    <TableCell>
                      {customer.student_phone_number}
                    </TableCell>
                    <TableCell>
                      {moment(customer.exam_details.created_on).format('LLLL')}
                    </TableCell>
                    <TableCell style={{textAlign: "center"}}>
                      <Button color='primary' variant='outlined'
                        onClick={(event) => handleViewResults(event, customer)}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        {/* </PerfectScrollbar> */}
        <TablePagination
          component="div"
          count={results.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 50, 75, 100, 200]}
        />
      </Card>
    </>
  );
};

ResultsList.propTypes = {
  results: PropTypes.array.isRequired
};

export default ResultsList;
