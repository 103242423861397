import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {FormControl, RadioGroup, Radio, FormLabel} from '@material-ui/core';

export const PersonalForm =({values, handleChange}) => {
  
  // const [values, setValues] = useState({
  //   firstName: '',
  //   lastName:'',
  //   email: '',
  //   birthday: '2021-05-16',
  //   gender: ''
  // });

  // const handleChange = (event) => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value
  //   });
  // };
  // // Callback to the parent checkout module for submit
  // personalDetailsCallback(values)


  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Personal Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="given-name"
            value={values.firstName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            value={values.lastName}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
              required
              id="email"
              name="email"
              label="Email"
              fullWidth
              value={values.email}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="date"
            name="birthday"
            label="Birthday"
            type="date"
            fullWidth
            value={values.birthday}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup aria-label="gender" name="gender" value={values.gender} onChange={handleChange} label="Gender">
              <FormControlLabel value="Female" control={<Radio />} label="Female" />
              <FormControlLabel value="Male" control={<Radio />} label="Male" />
              <FormControlLabel value="Other" control={<Radio />} label="Other" />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
