import jwt_decode from 'jwt-decode';
import React, { useState, useRef, useEffect } from 'react';
import { useScore } from '../../context/UserContext';
import { getSpecificRequest } from '../../requests/httpRequests';

import { Redirect } from 'react-router-dom';

const Results = () => {
	// score from context
	const { score } = useScore();
	// User from context
	// const token = useToken();
	
	// store all sets
	const answerBoxRef = useRef();

	const [question, setQuestion] = useState('');
	const [user, setUser] = useState({data:{
		address: "",
		date_of_birth: "",
		email: "",
		full_name: "",
		id: "",
		phone_number: "",
		profile_picture: "",
		role: ""
	}});

	useEffect(() => {
		if (score.set_id !== '') {
			const getAllQuestions = async () => {
				let response = await getSpecificRequest('/sets/all', score.set_id);
				if (response.status === 200) {
					setQuestion(response.data);
				}
			};
			getAllQuestions();
		}
		else {
			return "Your Exam Cannot be Submitted. Please contact the admin."
		}

		var token = localStorage.token;
		var isAuthenticated = localStorage.isAuthenticated
		if (token && isAuthenticated) {
			var tokenDetails = jwt_decode(token);
			const get_user = async () => {
				let response = await getSpecificRequest(
					'/users',
					tokenDetails.id,
					token
				);
				if (response.status === 200) {
					setUser(response);
				}
				// await getSpecificRequest(
				// 	'/users/status-toggle',
				// 	tokenDetails.id,
				// 	token
				// );
			};
			// Get Profile Picture and save it to user state
			get_user();
			
			const change_status = async () => {
				
				// if (response.status === 200) {
				// 	setUser(response);
				// }
			};
			// Get Profile Picture and save it to user state
			change_status();
		}
	}, [score]);

	if (Object.keys(score).length !== 0){
		// Get Key value pair of Question Number and Answer
		const studentsAnswersObj = {};
		score.Report.answers.forEach((record)=>{
			studentsAnswersObj[record.question_no] = record.given_answer
		});

		var questionLists = '';
		var questionListCounter = 1;
		if (question) {
			questionLists = question.groups.map((groups, indexI) =>
				groups.questions.sort((a, b) => { return a.question_id - b.question_id }).map((ques, indexJ) => (
					<>
						{questionListCounter === 1 && <h2>Reading</h2>}
						{questionListCounter === 21 && <>
							<br/><br/>
							<h2> Listening </h2>
							<br/><br/>
						</>}
						<p
							className={`resultQuestionBoxes ${
								'resultQuestionBox-' + ques.question_id
							} ${(studentsAnswersObj[ques.question_id] === ques.question_option_answer)? "right" : (studentsAnswersObj[ques.question_id]) ? "wrong" :""}`}
							key={indexJ}
						>
							{questionListCounter++}
						</p>
					</>
				))
			);
		}
		return (
			<div className="resultParent">
				<h1>Congratulations, your score is : {score ? score.Score + '/40' : ''}</h1>
				{user ? (
					<>
						<div className="result-picture">
							<img src={user.data.profile_picture} alt="Preview Unavailable"/>
						</div>
						<div className="result-student-details">
							Student Name: {user.data.full_name} <br/>
							Email: {user.data.email}
						</div>
						<hr/>
						<div className="result-answers result-right-answers">
							Right answers <br/>
							<span className="number"> {score.Score} </span>
						</div>
						<div className="result-answers result-wrong-answers">
							Wrong answers <br/>
							<span className="number" > {40-parseInt(score.Score)} </span>
						</div>
						<br/>
					</>
				) : <></>}
				<br />
				<div className="resultBox" ref={answerBoxRef}>
					{questionLists}
				</div>
			</div>
		);
	} 
	else{
		return (
			<>
				<h1> Go to Home</h1>
				<Redirect to="/" />
			</>
		)
	}
};

export default Results;
