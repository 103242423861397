import {useState, useEffect} from 'react'
// import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';

// // Custom Routes
import ResultsList from './resultsList';
import ResultsPage from './resultsPage';
import {getSpecificRequest } from '../../requests/httpRequests';
import { useToken } from '../../context/UserContext';

import jwt_decode from 'jwt-decode';



const Results = () => {
    
    const [results, setResults] = useState([]);
    const [answerSheet, setAnswersheet] = useState({});
    const [viewSingle, setViewSingle] = useState(0);

    const token = useToken();
    var tokenDetails = jwt_decode(token);

    // to load data all the languages on first page load.
	useEffect(() => {
		const getAllRecords = async () => {
			let response = await getSpecificRequest('/exam/student-report-paper', tokenDetails.id);
            console.log(response)
			if (response.status === 200) {
                setResults([response.data]);
			}
		};
		getAllRecords();

	}, []);

	return (
		<>
        {/* <Helmet>
            <title>Users</title>
            </Helmet> */}
            <Box
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                py: 3
            }}
            >
                {viewSingle ? (
                    <Container maxWidth={false}>
                        <Box sx={{ pt: 3 }}>
                        <ResultsPage 
                            setViewSingle = {setViewSingle}
                            answerSheet={answerSheet}
                        />
                        </Box>
                    </Container>
                ) : (
                    <Container maxWidth={false}>
                        <Box sx={{ pt: 3 }}>
                        <ResultsList
                            results={results}
                            setAnswersheet={setAnswersheet}
                            setViewSingle = {setViewSingle}
                            viewSingle = {viewSingle}
                        />
                        </Box>
                    </Container>
                )
                }
                
            </Box>
		</>
	);
};

export default Results;
