import jwt_decode from 'jwt-decode';
import Header from '../components/Header';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import {getSpecificRequest} from '../requests/httpRequests';
import { Button } from '@material-ui/core';

const MainLayout = () => {
	const [user, setUser] = useState({data:{}});
	
	useEffect(() => {
		var token = localStorage.token;
		var isAuthenticated = localStorage.isAuthenticated
		if (token && isAuthenticated) {
			var tokenDetails = jwt_decode(token);
			const get_user = async () => {
				let response = await getSpecificRequest(
					'/users',
					tokenDetails.id,
					token
					);
					if (response.status === 200) {
						setUser(response);
					}
				};
			// Get Profile Picture and save it to user state
			get_user();
		}
	}, []);

	if(user.data.email){
		return <Redirect to="/dashboard" />
	}
	return (
		<>
			<Header />
			<>
			<div>
				<link rel="icon" href="images/favicon.ico" type="image/x-icon" />
				<link rel="stylesheet" href="css/bootstrap.min.css" />
				<link rel="stylesheet" href="css/all.min.css" />
				<link rel="stylesheet" href="css/style.css" />
				<div className="container-fluid main-container" id="home">
					<div className="row-fluid main-nav">
					<nav className="navbar navbar-expand-lg fixed-top navbar-light bg-light my-navbar">
						<a className="navbar-brand nav-om-heading" href="/">
						OM KOREAN LANGUAGE INSTITUTE
						</a>
						<a className="nav-link" href="/login" style={{color: 'Red', fontWeight:'bolder', fontSize: '1.25em'}}> Login </a>
						<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon" />
						</button>
						<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav ml-auto">
							<li className="nav-item">
							<a className="nav-link" href="#home">Home</a>
							</li>
							<li className="nav-item">
							<a className="nav-link" href="#aboutUs">About Us</a>
							</li>
							<li className="nav-item">
							<a className="nav-link" href="#ourServices">Services</a>
							</li>
							<li className="nav-item">
							<a className="nav-link" href="#contactUs">Contact Us</a>
							</li>
							<li className="nav-item">
							<a className="nav-link" href="#downloads">Downloads</a>
							</li>
						</ul>
						</div>
					</nav>
					</div> {/* main nav ends here */}
					{/* notice  
							<div class="row-fluid imp-notice">
								<marquee class="col-lg-12 col-sm-12" behavior="" direction="">Notice: Bholi chutti!!</marquee>
							</div> notice ends here*/}
					<div className="image-slider">
					<div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
						<ol className="carousel-indicators">
						<li data-target="#carouselExampleIndicators" data-slide-to={0} className="active" />
						<li data-target="#carouselExampleIndicators" data-slide-to={1} />
						</ol>
						<div className="carousel-inner">
						<div className="carousel-item active">
							<img className="d-block w-100 h-100" src="images/imee.jpg" alt="First slide" />
						</div>
						<div className="carousel-item">
							<img className="d-block w-100" src="images/imee2.jpg" alt="Second slide" />
						</div>
						</div>
					</div>
					</div> {/* image slider ends here */}
					{/* about us begins here  */}
					<div className="row-fluid about-us" id="aboutUs">
					<div className="about-us-bg" />
					<h1 className="my-heading about-us-heading mt-5 mb-5">About Us</h1>
					<div className="row om-sir-image">
						<img src="images/omsir.jpg" alt="Mr. Om Burlakoti" />
						<h5 className="mt-3"><span>Founder:</span> Om Prasad Burlakoti Sir</h5>
					</div>
					<div className="row about-us-description mt-5 mb-5">
						<p>
						Our institute provides students with the best guidance with very reliable studying environment. The instructor Mr. Om Burlakoti has a lot of experience in these field and has master in Korean Language L6 with a outstanding result in Bishwo Bhasa campus.
						</p>
					</div>
					<div className="row om-sir-image">
						<img src="images/topik_result.png" alt="Level 6 Holder" />
						<h5 className="mt-3">Level 6 TOPIK Level Holder</h5>
					</div>
					</div> {/* about us ends here */}
					{/* our services begins here  */}
					<div className="row-fluid our-services" id="ourServices">
					<div className="our-services-bg" />
					<h1 className="my-heading our-services-heading mt-5 mb-5">Our Services</h1>
					<div className="row services-description mb-5">
						<div className="card col-md-3 col-sm-12 offset-md-0 services-1">
						<svg className="bi bi-chat-quote-fill services-icon" width="5rem" height="5rem" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" d="M16 8c0 3.866-3.582 7-8 7a9.06 9.06 0 01-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7zM7.194 6.766c.087.124.163.26.227.401.428.948.393 2.377-.942 3.706a.446.446 0 01-.612.01.405.405 0 01-.011-.59c.419-.416.672-.831.809-1.22-.269.165-.588.26-.93.26C4.775 9.333 4 8.587 4 7.667 4 6.747 4.776 6 5.734 6c.271 0 .528.06.756.166l.008.004c.169.07.327.182.469.324.085.083.161.174.227.272zM11 9.073c-.269.165-.588.26-.93.26-.958 0-1.735-.746-1.735-1.666 0-.92.777-1.667 1.734-1.667.271 0 .528.06.756.166l.008.004c.17.07.327.182.469.324.085.083.161.174.227.272.087.124.164.26.228.401.428.948.392 2.377-.942 3.706a.446.446 0 01-.613.01.405.405 0 01-.011-.59c.42-.416.672-.831.81-1.22z" clipRule="evenodd" />
						</svg>
						<div className="card-body">
							<h4 className="card-title font-weight-bold service-title">Language Classes</h4>
							<p className="card-text text-center service-text">
							We offer classes on Korean and Japanese languages.
							</p>
						</div>
						</div>
						<div className="card col-md-3 col-sm-12 offset-md-0 services-2">
						<svg className="bi bi-display-fill services-icon" width="5rem" height="5rem" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							<path d="M5.75 13.5c.167-.333.25-.833.25-1.5h4c0 .667.083 1.167.25 1.5H11a.5.5 0 010 1H5a.5.5 0 010-1h.75z" />
							<path fillRule="evenodd" d="M13.991 3H2c-.325 0-.502.078-.602.145a.758.758 0 00-.254.302A1.46 1.46 0 001 4.01V10c0 .325.078.502.145.602.07.105.17.188.302.254a1.464 1.464 0 00.538.143L2.01 11H14c.325 0 .502-.078.602-.145a.758.758 0 00.254-.302 1.464 1.464 0 00.143-.538L15 9.99V4c0-.325-.078-.502-.145-.602a.757.757 0 00-.302-.254A1.46 1.46 0 0013.99 3zM14 2H2C0 2 0 4 0 4v6c0 2 2 2 2 2h12c2 0 2-2 2-2V4c0-2-2-2-2-2z" clipRule="evenodd" />
							<path d="M2 4h12v6H2z" />
						</svg>
						<div className="card-body">
							<h4 className="card-title font-weight-bold service-title">CBT</h4>
							<p className="card-text text-center service-text">
							Computer Based Test available for Koream TOPIK exams
							</p>
						</div>
						</div>
						<div className="card col-md-3 col-sm-12 offset-md-0 services-3">
						<svg className="bi bi-person-lines-fill services-icon" width="5rem" height="5rem" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 100-6 3 3 0 000 6zm7 1.5a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5zm-2-3a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5zm2 9a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5z" clipRule="evenodd" />
						</svg>
						<div className="card-body">
							<h4 className="card-title font-weight-bold service-title">Online Student Portal</h4>
							<p className="card-text text-center service-text">
							Students can download and practice questions at home.
							</p>
						</div>
						</div>
						<div className="card col-md-3 col-sm-12 offset-md-0 services-4">
						<svg className="bi bi-chevron-double-down services-icon" width="5rem" height="5rem" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" d="M1.646 6.646a.5.5 0 01.708 0L8 12.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z" clipRule="evenodd" />
							<path fillRule="evenodd" d="M1.646 2.646a.5.5 0 01.708 0L8 8.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z" clipRule="evenodd" />
						</svg>
						<div className="card-body">
							<h4 className="card-title font-weight-bold service-title">Coming Soon</h4>
							<p className="card-text text-center service-text">
							Coming Soon
							</p>
						</div>
						</div>
					</div>
					</div> {/* our services ends here */}
					{/* contact us begins here  */}
					<div className="row-fluid contact-us" id="contactUs">
					<h1 className="my-heading contact-us-heading mt-5 mb-5">Contact Us</h1>
					<div className="row contact-us-main mb-5">
						<div className="col-sm-12 col-md-7">
						<div className="om-map">
							<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1304.8263110736234!2d85.35102690077015!3d27.719390059025965!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1962e16779a1%3A0x9d22dd060e5a5296!2sTara%20Goan%20Park%2C%20Boudhanath%20Marg%2C%20Kathmandu%2044602!5e0!3m2!1sen!2snp!4v1584967799687!5m2!1sen!2snp" frameBorder={0} style={{border: 0}} allowFullScreen aria-hidden="false" tabIndex={0} title='location-map' />
						</div>
						</div>
						<div className="col-sm-12 col-md-5 om-map-description-parent">
						<div className="om-map-description">
							<h4>
							<span>
								<svg className="bi bi-map" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" d="M15.817.613A.5.5 0 0116 1v13a.5.5 0 01-.402.49l-5 1a.502.502 0 01-.196 0L5.5 14.51l-4.902.98A.5.5 0 010 15V2a.5.5 0 01.402-.49l5-1a.5.5 0 01.196 0l4.902.98 4.902-.98a.5.5 0 01.415.103zM10 2.41l-4-.8v11.98l4 .8V2.41zm1 11.98l4-.8V1.61l-4 .8v11.98zm-6-.8V1.61l-4 .8v11.98l4-.8z" clipRule="evenodd" />
								</svg>
							</span>
							<span className="font-weight-bolder ml-2">Location: </span>Chuchhepati, Chabahil, Kathmandu (100m ahead from KL Tower.)
							</h4>
							<h4>
							<span>
								<svg className="bi bi-phone" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" d="M11 1H5a1 1 0 00-1 1v12a1 1 0 001 1h6a1 1 0 001-1V2a1 1 0 00-1-1zM5 0a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V2a2 2 0 00-2-2H5z" clipRule="evenodd" />
								<path fillRule="evenodd" d="M8 14a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
								</svg>
							</span>
							<span className="font-weight-bolder ml-2">Phone no: </span>01-5210524
							</h4>
							<h4>
							<svg className="bi bi-envelope-open" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" d="M.243 6.929l.514-.858L8 10.417l7.243-4.346.514.858L8 11.583.243 6.93z" clipRule="evenodd" />
								<path fillRule="evenodd" d="M7.184 10.68L.752 14.432l-.504-.864L6.68 9.816l.504.864zm1.632 0l6.432 3.752.504-.864L9.32 9.816l-.504.864z" clipRule="evenodd" />
								<path fillRule="evenodd" d="M8.47 1.318a1 1 0 00-.94 0l-6 3.2A1 1 0 001 5.4V14a1 1 0 001 1h12a1 1 0 001-1V5.4a1 1 0 00-.53-.882l-6-3.2zM7.06.435a2 2 0 011.882 0l6 3.2A2 2 0 0116 5.4V14a2 2 0 01-2 2H2a2 2 0 01-2-2V5.4a2 2 0 011.059-1.765l6-3.2z" clipRule="evenodd" />
							</svg>
							<span className="font-weight-bolder ml-2">Email: </span>omburlakoti2019@gmail.com
							</h4>
						</div>
						</div>
					</div>
					</div> {/* our services ends here */}
					{/* Footer */}
					<footer className="pt-4">
					<button className="btn btn-dark mb-3">Downloads</button>
					{/* Social buttons */}
					<h5>Find us on:</h5>
					<ul className="list-unstyled list-inline text-center">
						<li className="list-inline-item">
						<a className="btn-floating btn-fb mx-3" href="https://www.facebook.com/omkoreanandjapaneseinstitute">
							<i className="fab fa-facebook-f my-icon"> </i>
						</a>
						</li>
						<li className="list-inline-item">
						<a className="btn-floating btn-tw mx-3" href="#home">
							<i className="fab fa-twitter my-icon"> </i>
						</a>
						</li>
						<li className="list-inline-item">
						<a className="btn-floating btn-tw mx-3" href="#home">
							<i className="fab fa-instagram my-icon"> </i>
						</a>
						</li>
					</ul>
					{/* Social buttons */}
					{/* Copyright */}
					<div className="text-center mb-4">©2022  Copyright.
						<a href="#home"> Developed by: SilverBack Technology</a>
					</div>
					{/* Copyright */}
					</footer>
					{/* Footer */}
				</div>
				{/* container closes */}
				{/* scripts */}
				</div>
			</>
		</>
	);

};

export default MainLayout;
