import React, { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import { getRequest } from "../requests/httpRequests";

export const UserContext = React.createContext();
export const ScoreContext = React.createContext();

export const useToken = () => {
  return useContext(UserContext);
};

// Adding obtained score to the same context instead of creating a new for score only.
export const useScore = () => {
  return useContext(ScoreContext);
};

export const UserContextProvider = ({ children }) => {
  // Creating a variable and a score state to be accessed overall
  let token = localStorage.getItem("token");
  const [score, setScore] = useState({});

  const getAllRecords = async () => {
    let response = await getRequest("/users/verify-token/", token);
    if (response.status !== 200) {
      localStorage.removeItem("token");
      window.location.reload();
    }
  };

  // Handle the variable
  if (!token) {
    return <Redirect to="/" />;
  } else {
    getAllRecords();
  }

  return (
    <UserContext.Provider value={token}>
      <ScoreContext.Provider value={{ score, setScore }}>
        {children}
      </ScoreContext.Provider>
    </UserContext.Provider>
  );
};
