import { React, useState, useEffect, useRef, useCallback } from "react";
import Button from "@material-ui/core/Button";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from "@material-ui/core";

// Custom Routes
import { getRequest, getSpecificRequest } from "../../requests/httpRequests";

export default function GetSets({
  setShowQuestions,
  allQuestions,
  setAllQuestions,
  setSetNumber,
  setNumber,
}) {
  const [languageData, setLanguageData] = useState([]);
  const [languageNumber, setLanguageNumber] = useState("");

  const [setData, setSetData] = useState([]);
  const [buttionText, setButtonText] = useState("Go to exam...");
  const [buttonDisabledStatus, setButtonDisabledStatus] = useState(true);

  const firstLoad = useRef(0);

  const getAllQuestions = useCallback(async () => {
    const token = localStorage.getItem("token");
    let response = await getSpecificRequest("/sets/all", setNumber, token);
    if (response.status === 200) {
      if (response.data.status === false) {
        setButtonDisabledStatus(true);
        setButtonText("Go to exam...");
      } else {
        setButtonDisabledStatus(false);
        setAllQuestions(response.data);
      }
    }
  }, [setNumber, setAllQuestions]);

  // ANCHOR UseEffect
  useEffect(() => {
    // ANCHOR GET All Languages
    const getLanguages = async () => {
      let response = await getRequest("/language/");
      if (response.status === 200) {
        setLanguageData(response.data);
        firstLoad.current = 1;
      }
    };
    getLanguages();

    // Load Question Sets after Language is selected
    if (firstLoad.current === 1) {
      // ANCHOR GET Sets
      const getLanguageSets = async () => {
        let response = await getSpecificRequest(
          "/sets/language",
          languageNumber
        );
        if (response.status === 200) {
          let array = response.data;
          let filtered = array.filter((data) => data.status === true);
          setSetData(filtered);
          firstLoad.current = 2;
        }
      };
      getLanguageSets();
    }

    // Load Question Sets after Language is selected
    if (firstLoad.current === 2) {
      // ANCHOR Get All Questions
      getAllQuestions();
    }
  }, [firstLoad, languageNumber, setNumber, setAllQuestions, getAllQuestions]);

  useEffect(() => {
    getAllQuestions();
  }, [setNumber, getAllQuestions]);
  console.log("setNumber", setNumber);

  const onChange = (e) => {
    setLanguageNumber(e.target.value);
  };

  function onSetChange(e) {
    setSetNumber(e.target.value);
  }

  // function deepMap(obj, mapfn) {
  //     const questions_array = [];
  //     function recurse(obj) {
  //         let res = {}
  //         console.log(JSON.stringify(obj))
  //         for (const key in obj) {
  //             const value = obj[key];
  //             if (value && typeof value === 'object') {
  //                 res[key] = recurse(value);
  //             } else {
  //                 res[key] = mapfn(value);
  //             }
  //         }
  //         return res
  //     }
  //     return recurse(obj);
  // }
  // deepMap({'a':1, 'b':{'c':3}}, (val) => val+1) //{'a':2, 'b':{'c':4}}

  const takeTheExam = (questions) => {
    if (
      Object.keys(questions).length === 0 &&
      questions.constructor === Object
    ) {
      <h1>Please select a set first</h1>;
    } else {
      setShowQuestions(true);
    }
  };

  return (
    <>
      <Grid container>
        <Grid lg={3}>
          <FormControl style={{ marginTop: 10, minWidth: 250 }}>
            <InputLabel>Select A Language</InputLabel>
            <MuiSelect
              name="language"
              value={languageNumber}
              onChange={onChange}
            >
              {languageData.map((language, index) => (
                <MenuItem value={language.id} key={index}>
                  {language.language_name}
                </MenuItem>
              ))}
            </MuiSelect>
            {/* {error && (
                        <FormHelperText style={{ color: "#f44336" }}>{error}</FormHelperText>
                    )} */}
          </FormControl>
        </Grid>
        <Grid item lg={3}>
          <FormControl style={{ margin: 10, minWidth: 250 }}>
            <InputLabel>Select A Set</InputLabel>
            <MuiSelect name="set" value={setNumber} onChange={onSetChange}>
              {setData.map((set) => (
                <MenuItem value={set.set_id} key={set.set_name}>
                  {set.set_name}
                </MenuItem>
              ))}
            </MuiSelect>
          </FormControl>
        </Grid>
        <Grid lg={6}>
          <Button
            variant="contained"
            color="primary"
            disabled={buttonDisabledStatus}
            disableElevation
            onClick={(e) => takeTheExam(allQuestions)}
          >
            {buttionText}
          </Button>
        </Grid>
      </Grid>

      {/* <h2> Recent Uploads </h2> */}
    </>
  );
}
