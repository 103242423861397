// React Timer HOOK
import { useTimer } from 'react-timer-hook';

export default function MyTimer({ expiryTimestamp, handleSubmit }) {
    const {
        seconds,
        minutes,
    } = useTimer({ expiryTimestamp, onExpire: () => {
        handleSubmit();
        alert("Time up. Press Ok to submit your exam.");
    } });

    
    if (minutes === 5 && seconds===0) {
        const playAudio = async () => {
            const importRes = await import('../../assets/ping.wav'); // make sure the path is correct
            var audio = new Audio(importRes.default);
            try {
              await audio.play();
              alert("Only Five Minutes Remaining. Start reviewing your answers.");
            //   console.log("Playing audio");
            } catch (err) {
              console.log("Failed to play, error: " + err);
            }
        }
        playAudio();
    }

    return (
        <span style={{textAlign: 'center'}}>
          <span>
            <span>{minutes}</span>:<span>{seconds}</span>
          </span>
        </span>
      );
}