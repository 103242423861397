import React from "react";
import { Redirect, Route} from "react-router-dom";
import DashboardLayout from '../components/dashboard/DashboardLayout';

// User Context
import  {UserContextProvider} from '../context/UserContext';

function ProtectedRoute({ component:Component, ...restOfProps }) {
  const token = localStorage.getItem("token");

  return (
    <UserContextProvider>
      <Route
        {...restOfProps}
        render={(props) =>
          token ? <> <DashboardLayout Link path={props.path} history={props.history} component={<Component {...props}/>}/> </> 
          : <Redirect to="/login" />
        }
      />
    </UserContextProvider>
  );
}

export default ProtectedRoute;
