import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  }
}));

export default function Review({values}) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        User summary
      </Typography>
      <List disablePadding>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <ListItem className={classes.listItem}>
              <ListItemText primary={"First Name"} secondary={values.firstName} />
            </ListItem>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ListItem className={classes.listItem}>
              <ListItemText primary={"Last Name"} secondary={values.lastName} />
            </ListItem>
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <ListItem className={classes.listItem}>
              <ListItemText primary={"Email"} secondary={values.email} />
            </ListItem>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ListItem className={classes.listItem}>
              <ListItemText primary={"Birthday"} secondary={values.birthday} />
            </ListItem>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ListItem className={classes.listItem}>
              <ListItemText primary={"Gender"} secondary={values.gender} />
            </ListItem>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ListItem className={classes.listItem}>
              <ListItemText primary={"Primary Address"} secondary={values.address1} />
            </ListItem>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ListItem className={classes.listItem}>
              <ListItemText primary={"Secondary Address"} secondary={values.address2} />
            </ListItem>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ListItem className={classes.listItem}>
              <ListItemText primary={"City"} secondary={values.city} />
            </ListItem>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ListItem className={classes.listItem}>
              <ListItemText primary={"State"} secondary={values.state} />
            </ListItem>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ListItem className={classes.listItem}>
              <ListItemText primary={"Postal Code"} secondary={values.zip} />
            </ListItem>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ListItem className={classes.listItem}>
              <ListItemText primary={"Country"} secondary={values.country} />
            </ListItem>
          </Grid>
        </Grid>
      </List>
    </React.Fragment>
  );
}
