import {useState} from 'react';
import GetSets from "../components/exam/GetSets";
import Exam from "../components/exam/Exam";


export default function TakeExam({token}) {
    const [showQuestions, setShowQuestions] = useState(false);
    const [allQuestions, setAllQuestions] = useState({});
    const [setNumber, setSetNumber] = useState('');
    const [score, setScore] = useState('');


    return(
       <>
        {showQuestions ? <Exam 
                            questions={allQuestions}
                            setNumber={setNumber}
                            setScore={setScore}
                        /> 
                        :<GetSets 
                            setShowQuestions={setShowQuestions} 
                            setAllQuestions={setAllQuestions} 
                            allQuestions={allQuestions} 
                            setSetNumber={setSetNumber}
                            setNumber={setNumber}
                        />}
        
        <h1>{score}</h1>
       </>
    )
}