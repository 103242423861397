import { useEffect, useState } from "react";
// import { Helmet } from 'react-helmet';
import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// react toast
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getRequest } from "../../requests/httpRequests";

// change color of active or inactive set status
const useStyles = makeStyles({
  success: {
    background: "green !important",
  },
  danger: {
    background: "red !important",
  },
  show: {
    display: "inline-block !important",
  },
  hide: {
    display: "none !important",
  },
  groupDiv: {
    background: "#ffffff",
    width: "95%",
    margin: "10px auto",
    padding: "10px",
    borderRadius: "10px",
    "&>button": {
      background: "#cce5ff",
      color: "#3d3d3d",
      padding: "5px",
      border: "none",
    },
  },
  groupDivName: {
    display: "inline-block",
    fontWeight: "800",
    fontSize: "1.15rem",
  },
  groupDivType: {
    display: "inline-block",
  },
  groupDivEdit: {
    display: "inline-block",
    float: "right",
  },
  groupQuestionEdit: {
    float: "right",
  },
  groupQuestionName: {
    fontWeight: "600",
    fontSize: "1.25rem",
  },
  groupQuestionSection: {
    borderTop: "1px solid grey",
    marginTop: "10px",
    paddingTop: "10px",
    "&>span>button": {
      background: "#cce5ff",
      color: "#3d3d3d",
      padding: "3px",
      border: "none",
    },
  },
  groupQuestionInstruction: {
    border: "1px solid black",
    padding: "5px",
    margin: "5px",
  },
  groupQuestionNote: {
    borderBottom: "1px dotted black",
    margin: "5px",
  },
  groupQuestionOptions: {
    background: "#f5f5f5",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    margin: "10px",
    padding: "10px",
    fontSize: "1.15rem",
  },
  groupQuestionOptionsImage: {
    display: "block",
    width: "250px",
    height: "250px",
    margin: "1rem",
    "& img": {
      width: "90%",
      height: "auto",
      objectFit: "contain",
    },
  },
  questionImageDiv: {
    width: "400px",
    height: "350px",
    margin: "1rem auto",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
  GroupImageDiv: {
    width: "auto",
    height: "auto",
    margin: "1rem auto",
    objectFit: "contain",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },

  rightAnswer: {
    border: "2px solid green",
  },

  wrongAnswer: {
    border: "2px solid red",
  },
});

const ResultsPage = ({ answerSheet, setViewSingle }) => {
  const classes = useStyles();
  const [questionSetData, setQuestionSetData] = useState("");

  useEffect(() => {
    const getLanguages = async () => {
      const token = localStorage.getItem("token");
      let response = await getRequest(
        "/sets/all/" + answerSheet.exam_details.question_set_id + "/",
        token
      );
      if (response.status === 200) {
        setQuestionSetData(response.data);
      } else {
        toast.error(
          "Unable to fetch the question set. Please try again in a while."
        );
      }
    };
    getLanguages();
  }, [answerSheet.exam_details.question_set_id]);

  let answer_keys = {};
  // Map this array into object of key value pair
  answerSheet.exam_details.answers.forEach((element) => {
    answer_keys[element.question_no] = element.given_answer;
  });

  var pageContent = "";
  var questionCounter = 1;
  var optionCounter = 1;
  if (questionSetData) {
    pageContent = questionSetData.groups.map((question, i) => (
      <div className={classes.groupDiv}>
        <p className={classes.groupDivName}>
          Group name : {question.question_group_name}
        </p>
        <p className={classes.groupDivType}>
          &nbsp;({question.question_group_type})
        </p>
        {question.question_group_image && (
          <div className={classes.GroupImageDiv}>
            <img
              className={classes.questionImage}
              src={question.question_group_image}
              alt="Preview Unavailable"
            />
          </div>
        )}
        <div className={classes.groupQuestionSection}>
          {question.questions
            .sort((a, b) => {
              return a.question_id - b.question_id;
            })
            .map((qno, j) => (
              <span>
                <p className={classes.groupQuestionName}>
                  {questionCounter++ + " ) "}
                  {qno.question_content}
                </p>
                {qno.question_instruction && (
                  <p className={classes.groupQuestionInstruction}>
                    {qno.question_instruction}
                  </p>
                )}
                <p className={classes.groupQuestionNote}>
                  {qno.question_notes}
                </p>
                {qno.question_image_file && (
                  <div className={classes.questionImageDiv}>
                    <img
                      className={classes.questionImage}
                      src={qno.question_image_file}
                      alt="Preview Unavailable"
                    />
                  </div>
                )}
                {qno.question_audio_file && (
                  <audio controlsList="nodownload" preload="auto" controls>
                    <source src={qno.question_audio_file} type="audio/mpeg" />
                  </audio>
                )}
                {/* options List */}
                <div className={classes.groupQuestionOptions}>
                  {console.log(qno.question_option_answer)}
                  <p
                    className={`${classes.groupQuestionOption1} ${
                      qno.question_option_answer === 1
                        ? classes.rightAnswer
                        : ""
                    } ${
                      answer_keys[qno.question_id] === 1 &&
                      qno.question_option_answer !== 1 &&
                      classes.wrongAnswer
                    } `}
                  >
                    {optionCounter++ + ". "}
                    {qno.question_option_content_1.split(".").pop() === "png" ||
                    qno.question_option_content_1.split(".").pop() === "jpg" ||
                    qno.question_option_content_1.split(".").pop() ===
                      "jpeg" ? (
                      <span className={classes.groupQuestionOptionsImage}>
                        <img
                          src={qno.question_option_content_1}
                          alt="Preview Unavailable"
                        />
                      </span>
                    ) : qno.question_option_content_1.split(".").pop() ===
                      "mp3" ? (
                      <audio controlsList="nodownload" preload="auto" controls>
                        <source
                          src={qno.question_option_content_1}
                          type="audio/mpeg"
                        />
                      </audio>
                    ) : (
                      qno.question_option_content_1
                    )}
                  </p>
                  <p
                    className={`${classes.groupQuestionOption2} ${
                      qno.question_option_answer === 2
                        ? classes.rightAnswer
                        : ""
                    } ${
                      answer_keys[qno.question_id] === 2 &&
                      qno.question_option_answer !== 2 &&
                      classes.wrongAnswer
                    } `}
                  >
                    {optionCounter++ + ". "}
                    {qno.question_option_content_2.split(".").pop() === "png" ||
                    qno.question_option_content_2.split(".").pop() === "jpg" ||
                    qno.question_option_content_2.split(".").pop() ===
                      "jpeg" ? (
                      <span className={classes.groupQuestionOptionsImage}>
                        <img
                          src={qno.question_option_content_2}
                          alt="Preview Unavailable"
                        />
                      </span>
                    ) : qno.question_option_content_2.split(".").pop() ===
                      "mp3" ? (
                      <audio controlsList="nodownload" preload="auto" controls>
                        <source
                          src={qno.question_option_content_2}
                          type="audio/mpeg"
                        />
                      </audio>
                    ) : (
                      qno.question_option_content_2
                    )}
                  </p>
                  <p
                    className={`${classes.groupQuestionOption3} ${
                      qno.question_option_answer === 3
                        ? classes.rightAnswer
                        : ""
                    } ${
                      answer_keys[qno.question_id] === 3 &&
                      qno.question_option_answer !== 3 &&
                      classes.wrongAnswer
                    }`}
                  >
                    {optionCounter++ + ". "}
                    {qno.question_option_content_3.split(".").pop() === "png" ||
                    qno.question_option_content_3.split(".").pop() === "jpg" ||
                    qno.question_option_content_3.split(".").pop() ===
                      "jpeg" ? (
                      <span className={classes.groupQuestionOptionsImage}>
                        <img
                          src={qno.question_option_content_3}
                          alt="Preview Unavailable"
                        />
                      </span>
                    ) : qno.question_option_content_3.split(".").pop() ===
                      "mp3" ? (
                      <audio controlsList="nodownload" preload="auto" controls>
                        <source
                          src={qno.question_option_content_3}
                          type="audio/mpeg"
                        />
                      </audio>
                    ) : (
                      qno.question_option_content_3
                    )}
                  </p>
                  <p
                    className={`${classes.groupQuestionOption4} ${
                      qno.question_option_answer === 4
                        ? classes.rightAnswer
                        : ""
                    } ${
                      answer_keys[qno.question_id] === 4 &&
                      qno.question_option_answer !== 4 &&
                      classes.wrongAnswer
                    }`}
                  >
                    {optionCounter++ + ". "}
                    {qno.question_option_content_4.split(".").pop() === "png" ||
                    qno.question_option_content_4.split(".").pop() === "jpg" ||
                    qno.question_option_content_4.split(".").pop() ===
                      "jpeg" ? (
                      <span className={classes.groupQuestionOptionsImage}>
                        <img
                          src={qno.question_option_content_4}
                          alt="Preview Unavailable"
                        />
                      </span>
                    ) : qno.question_option_content_4.split(".").pop() ===
                      "mp3" ? (
                      <audio controlsList="nodownload" preload="auto" controls>
                        <source
                          src={qno.question_option_content_4}
                          type="audio/mpeg"
                        />
                      </audio>
                    ) : (
                      qno.question_option_content_4
                    )}
                    <span style={{ visibility: "hidden" }}>
                      {(optionCounter = 1)}
                    </span>
                  </p>
                  <hr />
                </div>
              </span>
            ))}
        </div>
      </div>
    ));
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      {/* <Helmet>
				<title>Edit Question | Om Korean</title>
			</Helmet> */}
      <Button onClick={() => setViewSingle(0)}>Back</Button> <br />
      <Box
        sx={{
          backgroundColor: "#fff",
          minHeight: "100%",
          px: 3,
          py: 3,
        }}
      >
        <h3 style={{ fontWeight: "normal" }}>
          Student Name: <strong> {answerSheet.student_name} </strong>{" "}
        </h3>
        <h3 style={{ fontWeight: "normal" }}>
          Marks Obtained:{" "}
          <strong> {answerSheet.exam_details.obtained_score} </strong>{" "}
        </h3>
        {/* {console.log(answerSheet)} */}
      </Box>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        {/* page content  */}
        {pageContent}
      </Box>
    </>
  );
};

export default ResultsPage;
