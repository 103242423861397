import { useState } from "react";
import "../assets/css/errors.css";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

// Material UI Components
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { InputAdornment } from "@material-ui/core";

// Custom imports
import { Details } from "../webDetails";
import authFunctions from "../requests/login";

// images import
import omLogo from "../assets/images/om.png";
import ubtLogo from "../assets/images/ubt.png";

import jwt_decode from "jwt-decode";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://silverback.technology/">
        {Details.signup_website}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  omLogo: {
    width: "170px",
  },
  omTitle: {
    fontFamily: "Georgia",
    letterSpacing: "5px",
  },
}));

export default function Login({ history }) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(0);

  const handleFormSubmit = async (data) => {
    data.email = data.email.toLowerCase();

    let res = await authFunctions.userLogin(data);

    if (res.status === 200) {
      const token = localStorage.getItem("token");
      var tokenDetails = jwt_decode(token);
      const userId = tokenDetails.id;
      const userAgent = navigator.userAgent.replace(/ /g, "").toString();
      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;
      const device_details = `${userAgent}_${screenWidth}_${screenHeight}`;

      if (tokenDetails.role === "student") {
        const existingSession = await authFunctions.getUserSession(userId);
        if (existingSession) {
          // if (existingSession.status) {
          //   return toast.error(
          //     "You are already logged in from another device."
          //   );
          // }
          if (!existingSession.device_info_2) {
            if (device_details !== existingSession.device_info) {
              await authFunctions.updateUserSession(userId, {
                device_info_2: device_details,
              });
              history.replace("/dashboard");
            }
          }

          const isRegesteredDevice =
            device_details === existingSession.device_info ||
            device_details === existingSession.device_info_2;
          if (!isRegesteredDevice) {
            return toast.error(
              "Unauthorized. Another device is setup for this account. Please call admin"
            );
          }
          await authFunctions.updateUserSessionStatus(userId);
        } else {
          const sessionData = {
            user_id: userId,
            device_info: device_details,
          };
          await authFunctions.createUserSession(sessionData);
        }
      }
      history.replace("/dashboard");
    } else {
      if (res.data === undefined) {
        toast.error(
          "The server is taking too long to respond. Please Try Again in a while."
        );
      } else if (res.data.message) {
        toast.error(res.data.message); // if error message is defined from backend
      } else {
        toast.error("Please Try Again in a while."); // if error message is not defined from backend
      }
    }
  };

  // React hook forms validation
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img className={classes.omLogo} src={omLogo} alt="Om Logo" />
          <Typography component="h1" variant="h5" className={classes.omTitle}>
            OM INSTITUTE
          </Typography>
          <img className={classes.omLogo} src={ubtLogo} alt="UBT Logo" />
          <form
            className={classes.form}
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <TextField
              {...register("email", {
                required: true,
                minLength: 5,
                pattern:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="이메일 (Email)"
              name="email"
              autoComplete="email"
              autoFocus
              error={errors.email !== undefined}
            />
            <small style={{ float: "right" }}>*응시번호 (Reg No)</small>
            {errors.email?.type === "required" && (
              <span className="error-alert" role="alert">
                Email is required
              </span>
            )}
            {errors.email?.type === "minLength" && (
              <span className="error-alert" role="alert">
                Minimum length of email must be 5 characters.
              </span>
            )}
            {errors.email?.type === "pattern" && (
              <span className="error-alert" role="alert">
                {" "}
                Please enter a valid email.
              </span>
            )}

            <TextField
              {...register("password", { required: true, minLength: 5 })}
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="비밀번호 (Password)"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <Button onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <VisibilityIcon />}
                    </Button>
                  </InputAdornment>
                ),
              }}
              error={errors.password !== undefined}
            />

            <small style={{ float: "right" }}>
              *생년월일 (8자리) (Date of Birth)
            </small>
            {errors.password?.type === "required" && (
              <span className="error-alert" role="alert">
                Password is required
              </span>
            )}
            {errors.password?.type === "minLength" && (
              <span className="error-alert" role="alert">
                Minimum length of password must be 5 characters.
              </span>
            )}
            <br />

            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}
