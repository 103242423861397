import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

// components
import Login from './pages/Login';
import MainLayout from './pages/Main';
import TakeExam from './pages/TakeExam';

import SignUp from './components/account/signup/SignUp';
import Dashboard from './components/dashboard/Dashboard';
import ResultPage from './components/exam/ResultPage';
import Result from './components/results/results';

//custom protected routes
import ProtectedRoute from './routes/ProtectedRoutes';

const history = createBrowserHistory();

const routes = (
	<>
		<Router history={history}>
			<Switch>
				<Route path="/" exact component={MainLayout} />
				<Route path={['/sign-in', '/login']} component={Login} />
				<Route path="/signup" component={SignUp} />

				<ProtectedRoute path="/dashboard" component={Dashboard} />
				<ProtectedRoute path="/take-exam" component={TakeExam} />
				<ProtectedRoute path="/results" component={ResultPage} />
				<ProtectedRoute path="/view-exam-paper" component={Result} />
			</Switch>
		</Router>
	</>
);

export default routes;
